



























































































































































































































import Base from '@/mixins/Base.vue';
import { IRole, IRolesResponse } from '@/interfaces/role';
import { IPagination } from '@/interfaces/pagination';

const component = Base.extend({
    data() {
        return {
            params: {
                active: null,
                source: null,
                name: '',
                sortBy: 'name',
                orderBy: 'asc',
                page: 1,

            },

            roles: {
                data: [] as Array<IRole>,
                meta: {} as IPagination | any,
            } as IRolesResponse,
        };
    },
    mounted() {
        this.getRoles();
    },
    methods: {
        getRoles(page: number = 1): void {
            this.params.page = page;

            this.get<IRolesResponse>('roles', {
                params: {
                    ...this.params,
                },
            }).then(({ data }) => {
                this.roles = data;
            });
        },
    },
});

export default component;
